import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';

interface WYSectionProps {
  title?: string;
  instruction?: string;
  info?: string;
}

const WYSection: FC<WYSectionProps> = ({ title, instruction, info }) => (
  <Container>
    <Title>{title}</Title>
    <Instruction>{instruction}</Instruction>
    <TYButtonsContainer>
      <AppleStoreButton type="WY" />
      <GooglePlayButton type="WY" />
    </TYButtonsContainer>
    <Info>{info}</Info>
  </Container>
);

export default WYSection;

const Container = styled.div`
  background: #e9f5f1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 3rem;

  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Title = styled.h2`
  color: #28293d;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.04rem;
  margin-bottom: 1.5rem;

  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.024rem;
    margin-bottom: 2rem;
  }
`;

const Instruction = styled.p`
  color: #28293d;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.024rem;
  margin-bottom: 1.5rem;
`;

const Info = styled.p`
  color: #28293d;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01375rem;

  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    letter-spacing: -0.024rem;
  }
`;

const TYButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.5rem;

  @media ${tablet} {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
`;
