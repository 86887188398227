import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { leadLanguage } from 'utils/functions';

interface OrderSummaryProps {
  title: string;
  trialFreeText?: string;
  wyXSellInfo?: string;
  xSellDownloadText?: string;
  scrollToWY: () => void;
  includesXSell?: boolean;
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  gap: 3rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Text)`
  @media ${tablet} {
    text-align: center;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const TrialItemContainer = styled(OrderItemContainer)`
  padding-bottom: 0;
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid rgba(64, 86, 185, 0.4);
  padding-top: 1.5rem;
  @media ${tablet} {
    padding-top: 1.5rem;
  }
`;

const TrialPriceContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  align-self: flex-start;
`;

const OldPrice = styled(Text)`
  text-decoration: line-through;
  color: #555770;
`;

const FreeLabel = styled(Text)`
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.25rem;
  background: #12845e;
  padding: 0.25rem 0.5rem;

  @media ${tablet} {
    font-size: 0.625rem;
  }
`;

const TrialTitle = styled(Text)`
  color: #1c1c28;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.024rem;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const TrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;

  @media ${tablet} {
    padding-bottom: 1.5rem;
    gap: 0.5rem;
  }
`;

const TrialDetails = styled.div`
  display: flex;
  gap: 1.5rem;

  @media ${tablet} {
    gap: 0.5rem;
  }
`;

const TrialImage = styled(DynamicImage)`
  width: 3.5rem;
  height: 5rem;

  @media ${tablet} {
    width: 2.125rem;
    height: 3rem;
  }
`;

const TrialColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @media ${tablet} {
    gap: 0.25rem;
  }
`;

const DownloadButton = styled.button`
  color: #12845e;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 1.625rem */
  letter-spacing: -0.03rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  background: none;
  border: none;
  text-align: left;
  width: fit-content;
  cursor: pointer;

  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: -0.012rem;
  }
`;

const TrialInfo = styled.p`
  color: #555770;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01375rem;
  padding-left: 4.5rem;

  @media ${tablet} {
    font-size: 1rem;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.024rem;
    padding-left: 2.3125rem;
  }
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  trialFreeText,
  wyXSellInfo,
  xSellDownloadText,
  scrollToWY,
  includesXSell,
}) => {
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-bw';
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products } = useSelector(
    (state: AppState) => state.user,
  );

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const productNames = useSelector(
    (state: AppState) => state?.config?.productNameMap,
  );

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);

    if (upsellProduct?.key.includes('brainway_walking-yoga_upsell')) {
      return (
        <TrialContainer>
          <TrialDetails>
            <TrialImage src="success/wy-app.png" alt="Walking Yoga app" />
            <TrialColumn>
              <TrialItemContainer key={index}>
                <TrialTitle type={isTablet ? 'body' : 'h3400'} color="dark80">
                  {upsellProduct?.name}
                </TrialTitle>
                <TrialPriceContainer>
                  <OldPrice type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {upsellProduct?.currency}
                    {upsellProduct?.key.includes('6-month')
                      ? (Number(upsellProduct?.finalPrice) / 2).toFixed(2)
                      : upsellProduct?.finalPrice}
                  </OldPrice>
                  <FreeLabel type={isTablet ? 'body' : 'h3400'} color="dark80">
                    {trialFreeText ?? 'Free'}
                  </FreeLabel>
                </TrialPriceContainer>
              </TrialItemContainer>
              <DownloadButton onClick={scrollToWY}>
                {xSellDownloadText ?? 'Download here'}
              </DownloadButton>
            </TrialColumn>
          </TrialDetails>
          <TrialInfo>{wyXSellInfo ?? 'Free 30-day trial'}</TrialInfo>
        </TrialContainer>
      );
    }

    return (
      <OrderItemContainer key={index}>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {productNames[upsellProduct?.key] || ''}
        </Text>
        <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
          {upsellProduct?.currency}
          {upsellProduct?.finalPrice}
        </Text>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce((sum, p) => {
    if (p?.hasTrial) {
      return sum + parseFloat(p?.discountedTrialPrice || '0');
    }

    return sum + parseFloat(p?.finalPrice || '0');
  }, 0);

  const totalPrice = (
    parseFloat(selectedProduct.finalPrice) + parseFloat(upsellsPrice)
  ).toFixed(2);
  const totalPriceTrial = (
    parseFloat(selectedProduct.discountedTrialPrice) + parseFloat(upsellsPrice)
  ).toFixed(2);

  const resolveProductName = (productName: string) => {
    if (includesXSell) {
      return productName;
    }

    if (leadLanguage(quiz) === 'es') {
      return productName.replace('de Brainway', '');
    }

    return productName.replace('Brainway ', '');
  };

  return (
    <Container>
      <Heading type={isTablet ? 'h2600' : 'h1'}>{title}</Heading>
      <SummaryContainer>
        <OrderItemContainer>
          <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
            {resolveProductName(productNames[selectedProduct?.key])}
          </Text>
          <Text type={isTablet ? 'body' : 'h3400'} color="dark80">
            {selectedProduct.currency}
            {selectedProduct.hasTrial
              ? selectedProduct.discountedTrialPrice
              : selectedProduct.finalPrice}
          </Text>
        </OrderItemContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            Total
          </Text>
          <Text type={isTablet ? 'tabActive' : 'h3'} color="dark80">
            {selectedProduct.currency}
            {selectedProduct.hasTrial ? totalPriceTrial : totalPrice}
          </Text>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;
