import React, { FC } from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

interface GooglePlayLinkBtnProps {
  type?: string;
}

const AppleStoreBadge = styled(GooglePlayLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;

const GooglePlayLinkBtn: FC<GooglePlayLinkBtnProps> = ({ type, ...props }) => {
  const resolveLink = () => {
    switch (type) {
      case 'WY':
        return StoresLinks.GooglePlayWY;
      default:
        return StoresLinks.GooglePlay;
    }
  };

  return (
    <AppleStoreBadge onClick={() => window.open(resolveLink(), '_system')} />
  );
};

export default GooglePlayLinkBtn;
