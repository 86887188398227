import React, { FC } from 'react';

import AppleStoreLogo from 'assets/icons/appStore_badge.svg';
import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

interface AppleStoreLinkBtnProps {
  type?: string;
}

const StyledLink = styled.a``;
const AppleStoreBadge = styled(AppleStoreLogo)`
  width: 10.21875rem;
  height: 2.95206rem;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
  cursor: pointer;
`;
const AppleStoreLinkBtn: FC<AppleStoreLinkBtnProps> = ({ type, ...props }) => {
  const resolveLink = () => {
    switch (type) {
      case 'WY':
        return StoresLinks.AppleStoreWY;
      default:
        return StoresLinks.AppleStore;
    }
  };

  return (
    <AppleStoreBadge onClick={() => window.open(resolveLink(), '_system')} />
  );
};

export default AppleStoreLinkBtn;
